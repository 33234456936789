<template>
    <div class="app-page task-page-wrapper">
        <transition v-if="task" name="fade">
            <div class="content">
                <div class="body">
                    <div class="section">
                        <div class="row">
                            <div :title="task.project.name" class="col-6 project-name text-overflow-ellipsis">
                                Project: <strong class="">{{ task.project.name }}</strong>
                            </div>
                            <div :title="task.project.name" class="col-6 project-name text-overflow-ellipsis">
                                Company:
                                <strong>
                                    {{ isTaskSubContractor ? task.internal_company.name : task.external_company.name }}
                                </strong>
                            </div>
                        </div>
                        <div class="task-name text-overflow-ellipsis">
                            <img v-if="task.is_main" src="@/assets/images/crown-emblem.svg" alt="crown"/>
                            <div :title="task.name" class="text-overflow-ellipsis">
                                <span>{{ task.name }}</span>
                            </div>
                        </div>
                        <p v-for="(inst, i) in instructions" :key="'inst' + i" class="task-instruction">
                            {{ instructions.length > 1 ? ++i + '.' : '' }} {{ inst }}
                        </p>
                    </div>
                    <div class="section">
                        <slot/>
                    </div>
                    <div class="section">
                        <div>Task ID: <strong>{{ task.task_id }}</strong></div>
                        <div class="row mt-4">
                            <div class="col-6">
                                <CNSelect v-model="formData.internal_assignee_id" :searchable="true" :caret="true"
                                          :class="{ 'opacity-75': isTaskSubContractor || !canEdit }"
                                          label="Internal Assignee" :disabled="isTaskSubContractor || !canEdit"
                                          :options="selectableUserList(task.internal_company.company_assigner_users)"/>
                            </div>
                            <div class="col-6">
                                <CNSelect v-model="formData.external_assignee_id" :searchable="true" :caret="true"
                                          label="External Assignee" :class="{ 'opacity-75': !canEdit }"
                                          :options="selectableUserList(task.external_company.company_assigner_users)"
                                          :disabled="!canEdit"/>
                            </div>
                        </div>
                        <div v-if="isContract" class="row mt-3">
                            <div v-if="isContract" class="col-6">
                                <CNSelect v-model="formData.internal_signer_id" :searchable="true" :caret="true"
                                          label="Internal Signer*" :disabled="isTaskSubContractor || !canEdit"
                                          :options="selectableUserList(task.internal_company.company_signer_users)"
                                          :class="{ 'opacity-75': isTaskSubContractor || !canEdit }"/>
                            </div>
                            <div class="col-6">
                                <CNSelect v-model="formData.external_signer_id" :searchable="true" :caret="true"
                                          label="External Signer*" :class="{ 'opacity-75': !canEdit }"
                                          :options="selectableUserList(task.external_company.company_signer_users)"
                                          :disabled="!canEdit"/>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <CNTextarea id="task-note" v-model="formData.note"
                                            :disabled="isTaskSubContractor || !canEdit"
                                            :class="{ 'opacity-75': isTaskSubContractor || !canEdit }"
                                            label="Description/Note:" style="height: 150px" :max-length="1000"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="side-panel">
                        <div class="category">Status</div>
                        <div>
                            <TaskStatusBadge :task="task" size="md"/>
                            <CBadge v-if="task.is_overdue" size="md" color="danger" class="ml-2">Overdue</CBadge>
                        </div>
                        <div v-if="isTaskGeneralContractor && isPendingApproval" class="approve">
                            Action:
                            <button @click="$emit('approve')"><img src="@/assets/icons/svg/approve.svg">Approve</button>
                            <button @click="$emit('reject')"><img src="@/assets/icons/svg/remove.svg">Reject</button>
                        </div>
                        <div class="category">Timeline</div>
                        <FLCalendar v-model="formData.start_date" label="Assign Date" class="opacity-75"
                                    :disabled="true"/>
                        <FLCalendar v-model="formData.due_date" label="Due Date"
                                    :disabled="isTaskSubContractor || !canEdit"
                                    :class="{ 'opacity-75': isTaskSubContractor || !canEdit }"/>
                        <CNInput :model-value="formatDate(this.task.complete_date)" label="Completed Date"
                                 class="opacity-75" :disabled="true"/>
                    </div>
                    <slot name="right-side"/>
                </div>
            </div>
        </transition>
        <div v-else class="w-full text-center py-5">
            <CSpinner color="primary"/>
        </div>
        <StickyFooter v-if="task && !isFullyExecuted && !isCompleted && canEdit">
            <CButton style="min-width: 100px" color="primary" variant="outline"
                     :disabled="loading || !canEdit || !formDirty" @click="toggleFormCancelModal">
                Cancel
            </CButton>
            <LoadingButton color="primary" :disabled="loading || !canEdit" :loading="loading" @click="save">
                Save
            </LoadingButton>
            <CButton v-if="isTaskGeneralContractor && canEdit" class="ml-auto d-flex align-items-center" variant="ghost"
                     :disabled="loading" @click="toggleTaskRevokeModal">
                <img src="@/assets/icons/svg/revoke.svg">
                <span class="text-error text-decoration-underline d-block ml-2">Revoke Task</span>
            </CButton>
        </StickyFooter>
    </div>
    <AppModal :visible="formCancelModal" title="Cancel changes?"
              text="Are you sure you want to cancel changes?" @close="toggleFormCancelModal">
        <template #footer>
            <CButton color="primary" variant="outline" @click="toggleFormCancelModal">No</CButton>
            <CButton color="primary" @click="cancel">Yes</CButton>
        </template>
    </AppModal>
    <AppModal content-class="reject-modal" :visible="taskRevokeModal" title="Are you sure you want to revoke the task?"
              text="This action can not be undone." @close="toggleTaskRevokeModal">
        <template #footer>
            <CButton color="primary" variant="outline" @click="toggleTaskRevokeModal">Cancel</CButton>
            <CButton color="primary" @click="revoke">Confirm</CButton>
        </template>
    </AppModal>
</template>

<script>

import CNSelect from "@/components/ui/CNSelect/CNSelect.vue";
import CNTextarea from "@/components/ui/CNTextarea/CNTextarea.vue";
import taskHelper from "@/mixin/taskHelper";
import {CLOSED_TASKS} from "@/domain/Entities/Task/taskStatuses";
import {isEqual} from "@/utils/helper";
import StickyFooter from "@/components/Forms/Partials/StickyFooter.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import AppModal from "@/components/Modals/AppModal.vue";
import FLCalendar from "@/components/Forms/Elements/FLCalendar.vue";
import CNInput from "@/components/ui/CNInput/CNInput.vue";
import TaskStatusBadge from "@/components/Task/TaskStatusBadge.vue";
import dateFormater from "@/mixin/dateFormater";
import {mapActions} from "vuex";

export default {
    name: 'TaskPageWrapper',
    components: {TaskStatusBadge, CNInput, FLCalendar, AppModal, LoadingButton, StickyFooter, CNTextarea, CNSelect},
    mixins: [taskHelper, dateFormater],
    emits: ['update', 'approve', 'reject'],
    props: {
        task: {
            type: Object,
            default: null
        },
        instructions: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            loading: false,
            formCancelModal: false,
            taskRevokeModal: false,
            formOrigin: null,
            formData: {
                internal_assignee_id: null,
                external_assignee_id: null,
                internal_signer_id: null,
                external_signer_id: null,
                start_date: null,
                due_date: null,
                note: null,
            },
        }
    },
    computed: {
        canEdit() {
            return this.$store.state.tasks.permissions.edit && !CLOSED_TASKS.includes(this.task.status);
        },
        formDirty() {
            return this.formOrigin && !isEqual(this.formData, this.formOrigin);
        },
    },
    watch: {
        task(task) {
            this.setTaskData();
        }
    },
    mounted() {
        this.setTaskData();
    },
    methods: {
        ...mapActions(['setTask']),
        save() {
            this.toggleLoading();

            this.$http.tasks
                .editTasks(this.defineTaskSaveData())
                .then(response => {
                    this.toast('info', response.data.message);
                    this.updateEvent();
                })
                .catch((e) => this.toast('warning', e.response.data.message))
                .finally(() => this.toggleLoading());
        },
        defineTaskSaveData() {
            return {
                task_id: this.task.id,
                ...this.formData,
            };
        },
        revoke() {
            this.$http.tasks.revokeTask(this.task.id).then((res) => {
                this.toast('info', res.data.message);
                this.toggleTaskRevokeModal();
                this.updateEvent();
            });
        },
        selectableUserList(list) {
            return list.map(user => ({value: user.id, name: user.full_name}));
        },
        setTaskData() {
            if (!this.task) return;

            this.formData.internal_assignee_id = this.task.internal_assignee_id;
            this.formData.external_assignee_id = this.task.external_assignee_id;
            this.formData.internal_signer_id = this.task.internal_signer_id;
            this.formData.external_signer_id = this.task.external_signer_id;
            this.formData.start_date = this.task.start_date;
            this.formData.due_date = this.task.due_date;
            this.formData.note = this.task.note;
            this.formOrigin = {...this.formData};
            this.setTask(this.task);
        },
        cancel() {
            this.formData = {...this.formOrigin};
            this.toggleFormCancelModal();
        },
        updateEvent() {
            this.$emit('update');
        },
        toggleFormCancelModal() {
            this.formCancelModal = !this.formCancelModal;
        },
        toggleTaskRevokeModal() {
            this.taskRevokeModal = !this.taskRevokeModal;
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    }
};
</script>

<style lang="scss" scoped>
.task-page-wrapper {
    .content {
        gap: 24px;
        display: grid;
        grid-template-columns: minmax(auto, 875px) 325px;
        max-width: 1200px;

        .body {
            .section {
                margin-bottom: 32px;

                .project-name {
                    color: #677a89;
                    font-size: 16px;
                    margin-bottom: 24px;

                    & strong {
                        font-weight: 600;
                        font-size: 16px;
                    }
                }

                .task-name {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #1c262f;
                    border-bottom: 1px solid #c3cdd5;
                    margin-bottom: 15px;

                    & img {
                        height: 24px;
                    }
                }

                .task-instruction {
                    font-size: 16px;
                    color: #1c262f;
                    margin-bottom: 0;
                }
            }
        }

        .side-panel {
            width: 100%;
            padding: 16px 16px 24px;
            background: #f2f5f8;
            border-radius: 8px;
            display: flex;
            flex-grow: 0;
            flex-direction: column;
            gap: 24px;

            .category {
                font-size: 18px;
                font-weight: 600;
                color: #1c262f;
                padding-bottom: 8px;
                border-bottom: 1px solid #9fafbc;
            }

            .approve {
                display: flex;
                align-items: center;
                gap: 16px;

                & button {
                    display: flex;
                    align-items: center;
                    background: none;
                    border: none;
                    outline: none;
                    gap: 8px;
                    color: rgba(28, 38, 47, 1);
                    font-weight: 600;
                    font-size: 14px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
</style>
